import React from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  TableSortLabel,
  Chip,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Close } from "@mui/icons-material";

const modalStyle = (isSmallScreen) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isSmallScreen ? "95%" : "75%",
  maxWidth: 900,
  bgcolor: "#f5f5f5", // Light grey background
  boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.15)",
  borderRadius: 16,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
});

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const closeButtonStyle = {
  color: red[600],
  "&:hover": {
    color: red[800],
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
  },
};

const tableContainerStyle = {
  flex: 1,
  marginTop: 2,
  borderRadius: 8,
  overflowY: "auto",
  maxHeight: "60vh",
};

const tableHeadStyle = {
  backgroundColor: "#003366",
};

const tableCellStyle = {
  fontWeight: "bold",
  backgroundColor: "#003366",
  color: "#ffff",
};

const rowHoverStyle = {
  "&:hover": {
    backgroundColor: "#e8f0fe",
  },
};

const DataModal = ({
  open,
  onClose,
  processingPilots,
  waitingPilots,
  failedPilots,
  viewType,
  loading,
  error,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const renderTableContent = (data, columns) => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} align="center">
            <Typography variant="h6" color="textSecondary">
              No data available
            </Typography>
          </TableCell>
        </TableRow>
      );
    }

    return data.map((item, index) => (
      <TableRow key={item.requirement_id} sx={rowHoverStyle}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{item.requirement_id}</TableCell>
        <TableCell>
          {viewType === "failed" ? (
            item.requirement_creation_failure_reason || "N/A"
          ) : (
            <Chip
              label={
                viewType === "waiting"
                  ? "Waiting"
                  : item.processing_status || "N/A"
              }
              color={viewType === "waiting" ? "secondary" : "primary"}
              variant="outlined"
            />
          )}
        </TableCell>
      </TableRow>
    ));
  };

  const getTableData = () => {
    switch (viewType) {
      case "failed":
        return {
          data: failedPilots,
          columns: ["SNO", "Requirement ID", "Reason"],
        };
      case "processing":
        return {
          data: processingPilots,
          columns: ["SNO", "Requirement ID", "Status"],
        };
      case "waiting":
        return {
          data: waitingPilots,
          columns: ["SNO", "Requirement ID", "Status"],
        };
      default:
        return { data: [], columns: [] };
    }
  };

  const { data, columns } = getTableData();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle(isSmallScreen)}>
        <Box sx={headerStyle}>
          <Typography variant="h6" color="#003366">
            {viewType.charAt(0).toUpperCase() + viewType.slice(1)} Pilot Reports
          </Typography>
          <IconButton sx={closeButtonStyle} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ marginBottom: 2 }} />

        <TableContainer component={Paper} sx={tableContainerStyle}>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            aria-label={`${viewType} pilots table`}
          >
            <TableHead sx={tableHeadStyle}>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index} sx={tableCellStyle}>
                    <TableSortLabel>{col}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableContent(data, columns)}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default DataModal;
