import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Box,
  Menu,
  MenuItem,
  Collapse,
  useMediaQuery,
  useTheme,
  styled,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Portrait from "@mui/icons-material/Portrait";
import BuildIcon from '@mui/icons-material/Build';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarState, toggleSidebar } from "../redux/actions/sidebarActions";
import { AccountBox, AccountTree, Assignment, ContactSupport, Group, Settings, SettingsSuggest } from "@mui/icons-material";

// Constants for drawer width
const drawerWidth = 240;

// Styled components for the sidebar
const SidebarIcon = styled(ListItemIcon)(({ theme, isActive, sidebarOpen }) => ({
  color: isActive ? "#1f5b8b" : "#7f7f7f",
  justifyContent: "center",
  transition: "all 0.3s ease",
  fontSize: sidebarOpen ? 24 : 30, // Larger icons when sidebar is collapsed
}));

const SidebarText = styled(ListItemText)(({ isActive, sidebarOpen }) => ({
  display: sidebarOpen ? "inline" : "none", // Hide text when sidebar is collapsed
  transition: "opacity 0.3s",
  color: isActive ? "#1f5b8b" : "#000000de",
}));

const SidebarItem = styled(ListItem)(({ theme, sidebarOpen, selected }) => ({
  padding: sidebarOpen ? theme.spacing(1, 2) : theme.spacing(1),
  display: "flex", // Always display items to show icons even when collapsed
  justifyContent: sidebarOpen ? "flex-start" : "center",
  minHeight: 48,
  transition: "padding 0.3s, background-color 0.3s",
  borderRadius: sidebarOpen ? theme.shape.borderRadius : "10%", // Circular effect when collapsed
  backgroundColor: selected ? theme.palette.action.selected : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    transform: "scale(1.05)",
  },
}));

// Sidebar container with white background
const SidebarContainer = styled(Drawer)(({ theme, sidebarOpen }) => ({
  "& .MuiDrawer-paper": {
    background: "#fff", // White background
    transition: "width 0.3s ease",
    width: sidebarOpen ? drawerWidth : theme.spacing(9),
    boxSizing: "border-box",
    overflowX: "hidden", // Ensure overflow doesn't cause horizontal scrolling
  },
}));

// Top bar with blue gradient
const Topbar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(267deg, #003366, #053f83)",
  zIndex: theme.zIndex.drawer + 1,
}));

const sections = [
  {
    items: [
      { text: "Dashboard", icon: <HomeIcon />, path: "home" },
      { text: "Reports", icon: <AssessmentIcon />, path: "reports" },
      { text: "My Resumes", icon: <DescriptionIcon />, path: "myresumes" },
      // { text: "My Profile", icon: <Portrait />, path: "myprofile" },
    ],
  },
  {
    label: "Account",
    items: [
      { text: "Settings", icon: <SettingsIcon />, path: "settings" },
    ],
  },
];

const moreSettings = [
  { text: "Customer", icon: <AccountBox />, path: "customer" },
  { text: "User", icon: <Group />, path: "user" },
  { text: "Assignment", icon: <Assignment />, path: "assignment" },
  { text: "Global Settings", icon: <SettingsSuggest />, path: "globalSettings" },
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.user.sidebarOpen);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState("User");
  const [role, setRole] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const username = localStorage.getItem("username");
  const userData = localStorage.getItem("user");
  const getrole = JSON.parse(userData);
  const userisActive = getrole.is_active;

  useEffect(() => {
    setRole(getrole.role);

    if (username) {
      setLoggedInUser(username);
    }
    if (!userisActive) {
      navigate("/not-activated");
    }
  }, [userisActive]);

  useEffect(() => {
    const handleResize = () => {
      const savedSidebarState = JSON.parse(localStorage.getItem("sidebarOpen"));
      if (window.innerWidth <= 600) {
        dispatch(setSidebarState(false));
      } else if (savedSidebarState !== null) {
        dispatch(setSidebarState(savedSidebarState));
      } else {
        dispatch(setSidebarState(true));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const handleSidebarToggle = () => {
    const newState = !sidebarOpen;
    dispatch(toggleSidebar(newState));
    localStorage.setItem("sidebarOpen", JSON.stringify(newState));
  };

  const handleNavigation = (path) => {
    if (path === "Logout") {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("user");
      navigate("/"); // Navigate to home route after logout
    } else {
      navigate(`/${path}`);
    }
  };

  const isActive = (path) => location.pathname === `/${path}`;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  const renderSidebarItems = (items) => {

   return items
      .filter((item) => {
        if (item.text === "Reports") {
          return  role === "admin" || role === "manager" || role === "account_manager";
        } else if (item.text === "My Resumes") {
          return role === "recruiter_processing" || role === "lead_processing" || role === "admin";
        }else if (item.text === "Settings") {
          return role === "admin" || role === "manager" || role === "account_manager";
        }
        else if (item.text === "Customer") {
          return role === "admin" || role === "manager" || role === "account_manager";
        } else if (item.text === "User") {
          return role === "admin" || role === "manager" || role === "account_manager";
        } else if (item.text === "Assignment") {
          return role === "admin" || role === "manager" || role === "account_manager";
        } else if (item.text === "Global Settings") {
          return role === "admin";
        }
        return true; // Show all other items
      })
      .map((item) => (
        <SidebarItem
          key={item.text}
          button
          onClick={() => {
            item.text === "Settings" ? handleSettingsToggle() : handleNavigation(item.path);
          }}
          selected={isActive(item.path)}
          sidebarOpen={sidebarOpen}
        >
          <SidebarIcon isActive={isActive(item.path)} sidebarOpen={sidebarOpen}>
            {item.icon}
          </SidebarIcon>
          <SidebarText isActive={isActive(item.path)} sidebarOpen={sidebarOpen}>
            <Typography variant="body2">{item.text}</Typography>
          </SidebarText>
          {item.text === "Settings" && sidebarOpen && (
            settingsOpen ? <ExpandLess /> : <ExpandMore />
          )}
        </SidebarItem>
      ))
    };

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: sidebarOpen ? "flex-start" : "center",
            padding: theme.spacing(1),
            backgroundColor: "#fff", // White background for sidebar top section
          }}
        >
          {/* Logo and User name in expanded view */}
          {sidebarOpen ? (
            <>
              <img
                src="/path-to-logo" // Replace with your logo path
                alt="logo"
                style={{ height: "40px", marginRight: theme.spacing(2) }}
              />
              <Typography variant="h6">Company Name</Typography>
            </>
          ) : (
            <img
              src="/path-to-logo"
              alt="logo"
              style={{ height: "40px" }}
            />
          )}
        </Toolbar>
        <List>{sections.map((section) => renderSidebarItems(section.items))}</List>
        {/* Settings Sub-menu */}
        {settingsOpen && (
          <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderSidebarItems(moreSettings)}
            </List>
          </Collapse>
        )}
      </Box>
      <Divider />
      <Box>
        <List>
          <SidebarItem
            button
            onClick={() => handleNavigation("contact")}
            sidebarOpen={sidebarOpen}
          >
            <SidebarIcon sidebarOpen={sidebarOpen}>
            <ContactSupport />
            </SidebarIcon>
            <SidebarText sidebarOpen={sidebarOpen}>
              <Typography variant="body2">Contact Jinn</Typography>
            </SidebarText>
          </SidebarItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Topbar with blue gradient */}
      <Topbar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            {sidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <img
              src="./jinne_head.png" // Replace with your logo URL
              alt="Logo"
              style={{ height: "40px", marginRight: theme.spacing(1) }}
            />
          <Typography variant="h6" noWrap component="div">
          Jinn Hire
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton size="large" aria-label="show notifications" color="inherit">
              <NotificationsIcon />
            </IconButton>
            <IconButton size="large" edge="end" color="inherit" onClick={handleMenuOpen}>
              <AccountCircle />
              <Typography
              variant="body2"
              color="text.primary"
              style={{ color: "inherit" }}
              sx={{ ml: 1 }}
            >
              {loggedInUser.split("@")[0]}
            </Typography>
            </IconButton>
            <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                mt: 2, // Adjust this value as needed to position the menu below the AppBar
                boxShadow: 'none', // Remove shadow
                borderRadius: 1,
                minWidth: 150,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
              },
            }}
          >
            <MenuItem onClick={() => handleNavigation("Logout")}>
              <LogoutIcon sx={{ marginRight: '8px' }} /> Logout
            </MenuItem>
          </Menu>
          </Box>
        </Toolbar>
      </Topbar>
      {/* Sidebar Drawer */}
      <SidebarContainer
        variant={isMobile ? "temporary" : "permanent"}
        open={sidebarOpen}
        onClose={handleSidebarToggle}
        sidebarOpen={sidebarOpen}
      >
        {drawer}
      </SidebarContainer>
      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          ml: sidebarOpen ? `200px` : theme.spacing(4), // Shift content based on sidebar width
          transition: "margin-left 0.3s ease",
        }}
      >
        {/* Your main content here */}
      </Box>
    </Box>
  );
};

export default Sidebar;