import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
  Alert,
  Paper,
  TablePagination,
  Box,
  ThemeProvider,
  createTheme,
  IconButton,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Tooltip,
  CircularProgress,
  Divider,
  Switch,
  Menu,
  MenuItem as MenuActionItem,
  TextField,
  Avatar,
  RadioGroup,
  Radio,
  Chip,
  Grid,
  Skeleton,
  Card,
  InputBase,
} from "@mui/material";
import {
  CheckCircle,
  Cancel,
  ExpandMore,
  Check,
  RemoveRedEye,
  Lock,
  LockOpen,
  Download,
  DownloadDone,
  MoreVert,
  SkipNext,
  Visibility,
  VisibilityRounded,
  DescriptionSharp,
  WorkOutline,
  CurrencyRupee,
  LocationCity,
  RemoveRedEyeOutlined,
  WorkHistory,
  PanoramaFishEye,
  Expand,
  ExpandLess,
} from "@mui/icons-material";
import axios from "axios";
import { AppContext } from "./AppContext";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ResumeComponent from "./ResumeComponent";
import NoDataCard from "./NoDataCard";
import CandidateEvaluationModal from "./CandidateEvaluationModal";
import CandidateCommentsModal from "./CandidateCommentsModal";
import CandidateModal from "./CandidateModal";
import SearchIcon from "@mui/icons-material/Search";
import RequirementQuestionsModal from "./RequirementQuestionsModal";
import CandidateInteractionModal from "./CandidateInteractionModal";

// Define a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#f0f4f7",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0288d1",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
        },
      },
    },
  },
});

// Define custom styles for checkboxes
const checkboxStyles = {
  positive: {
    "&.Mui-checked": {
      color: "green",
    },
    color: "green",
  },
  negative: {
    "&.Mui-checked": {
      color: "red",
    },
    color: "red",
  },
};

const chipColors = {
  communication_failed: "error",
  suitable_profiles: "success",
  profiles_needing_intervention: "warning",
  unsuccessful_profiles: "error",
  in_progress: "info",
  no_nesponse: "warning",
  dormant_profiles: "default",
};

const Process = ({ optedInRequirements, handleToggle }) => {
  const [profiles, setProfiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [selectedProfileQuestions, setSelectedProfileQuestions] = useState([]);
  const [jdModalOpen, setJdModalOpen] = useState(false);
  const [jdContent, setJdContent] = useState("");
  const [resumeId, setResumeID] = useState("");
  const [requirementId, setRequirementId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [lockStatus, setLockStatus] = useState({});
  const [fileLinks, setFileLinks] = useState({});
  const [optedIn, setOptedIn] = useState(
    optedInRequirements.reduce((acc, req) => ({ ...acc, [req]: true }), {})
  );
  const [statusBy, setStatusBy] = useState("default");
  const [anchorEl, setAnchorEl] = useState(null);
  const initialRender = useRef(true);
  const { resumeAssignmentStrategy } = useContext(AppContext);
  const previousTab = useRef(selectedTab);
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [loadingComments, setLoadingComments] = useState(true);
  const [candidateData, setCandidateData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { customerData } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const [selectedComment, setSelectedComment] = useState("");
  const [isQuestionsModalOpen, setIsQuestionsModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);

  const predefinedComments = [
    "Candidate is not answering the calls",
    "Another recruiter is already working on this profile",
    "Candidate asked to call back later",
    "Candidate phone is off",
    "Other (Please specify below)",
  ];
  const tableHeaders = {
    0: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Remarks",
      "Skills",
      "Actions",
    ],
    1: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Email Address",
      "Current Question",
      "Actions",
      "Questions List",
    ],
    2: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Email Address",
      "Current Question",
      "Reason for Intervention",
      "Actions",
      "Questions List",
    ],
    3: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Email Address",
      "Current Question",
      "Reason for Failure",
      "Actions",
      "Questions List",
    ],
    4: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Email Address",
      "Current Question",
      "Last Follow-up Time",
      "Waiting Time",
      "Actions",
      "Questions List",
    ],
    5: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Current Question",
      "Follow-up Count",
      "Actions",
      "Questions List",
    ],
    6: [
      "SNO",
      "Job Description ID",
      "Candidate Name",
      "Phone",
      "Email Address",
      "Current Question",
      "Waiting Time",
      "Actions",
      "Questions List",
    ],
  };

  const suitableOptions = [
    {
      text: "Candidate is interested in the position",
      style: checkboxStyles.positive,
    },
    { text: "Candidate is strong technically", style: checkboxStyles.positive },
    { text: "Candidate will join", style: checkboxStyles.positive },
    { text: "Candidate can join immediately", style: checkboxStyles.positive },
    { text: "Candidate seems genuine", style: checkboxStyles.positive },
  ];

  const notSuitableOptions = [
    {
      text: "Candidate is not interested in the role",
      style: checkboxStyles.negative,
    },
    { text: "Candidate has high expectations", style: checkboxStyles.negative },
    { text: "Candidate may not join", style: checkboxStyles.negative },
    { text: "Candidate notice period is high", style: checkboxStyles.negative },
    { text: "Candidate is not responding", style: checkboxStyles.negative },
    {
      text: "Candidate is not suitable/ irrelevent",
      style: checkboxStyles.negative,
    },
    {
      text: "Candidate is not looking for change",
      style: checkboxStyles.negative,
    },
    { text: "Candidate might not relocate", style: checkboxStyles.negative },
    {
      text: "Candidate is not having good communication skills",
      style: checkboxStyles.negative,
    },
    {
      text: "Candidate is not having good technical skills",
      style: checkboxStyles.negative,
    },
    {
      text: "Other (Please specify below)",
      style: checkboxStyles.negative,
    },
  ];

  const userData = localStorage.getItem("user");
  const parsedUserData = JSON.parse(userData);

  const handleSkip = async (comments) => {
    const token = localStorage.getItem("token");
    if (!resumeId) {
      console.warn("resumeId is empty, skipping API call");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.jinnhire.in/jinnhire/data/resumes/skip_resume/",
        {
          resume_id: resumeId,
          comment: commentInput,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log("Resume skipped successfully", response.data);
      // Add the new comment to the comments list
      setComments([...comments, response?.data]);
      setCommentInput("");
    } catch (error) {
      console.error("Error skipping resume", error);
    }
  };

  const fetchProfiles = async (tabIndex) => {
    let reasonForConversationStopping = "";
    let additionalParams = {};

    switch (tabIndex) {
      case 0:
        fetchCommunicationFailedProfiles();
        return;
      case 1:
        reasonForConversationStopping = "success";
        break;
      case 2:
        reasonForConversationStopping = "not_interested";
        break;
      case 3:
        reasonForConversationStopping = "not_qualified";
        break;
      case 4:
        reasonForConversationStopping = "continuing";
        break;
      case 5:
        reasonForConversationStopping = "waiting";
        additionalParams = { conversation_continuing: "YES" };
        break;
      case 6:
        reasonForConversationStopping = "waiting";
        additionalParams = { conversation_continuing: "FUTURE" };
        break;
      default:
        break;
    }

    try {
      const token = localStorage.getItem("token");
      const username = localStorage.getItem("username");
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/candidate-state-machines/?username=${username}&reason_for_conversation_stopping=${reasonForConversationStopping}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          params: additionalParams,
        }
      );
      const data = response.data;
      setProfiles(data);
    } catch (error) {
      setErrorMessage("Error fetching profiles");
    } finally {
      setLoading(false);
    }
  };

  const getChipColor = (status) => chipColors[status] || "default";

  // Function to format the status value
  const formatStatus = (status) => {
    return status
      .replace(/_/g, " ")
      .toUpperCase()
      .split(" ")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    if (initialRender.current) {
      // Call fetchProfiles only once on initial render
      fetchProfiles(selectedTab);
      initialRender.current = false;
    } else {
      // Handle tab change: call handleSkip if tab changes from 0 to another tab
      if (previousTab.current === 0 && selectedTab !== 0) {
        handleSkip();
      }
    }

    // Update previousTab ref to the current selectedTab
    previousTab.current = selectedTab;
  }, [selectedTab, handleSkip, fetchProfiles]);

  useEffect(() => {
    if (profiles.length > 0) {
      updateLockStatus();
    }
  }, [profiles]);

  const fetchCommunicationFailedProfiles = async (statusBy, jdId) => {
    try {
      const resumesResponse = await (resumeAssignmentStrategy === "pool_based"
        ? fetchResumesFromPool(statusBy)
        : fetchResumesByStatus("communication_failed"));

      let profilesData = [];

      if (Array.isArray(resumesResponse)) {
        // Case where fetchResumesFromPool returns an array of resumes
        profilesData = resumesResponse.map((resumeData) => ({
          resume_id: resumeData.resume_id,
          jd_id: resumeData.requirement_id,
          candidate_fname: resumeData.insights[0]?.first_name || "",
          candidate_last_name: resumeData.insights[0]?.last_name || "",
          candidate_phone_number: resumeData.phone_number || "",
          emailAddress: resumeData.insights[0]?.email_id || "",
          state: resumeData.state || "",
          file_link: resumeData.file_link,
          remarks: resumeData.remarks || "",
          skills: resumeData.insights[0]?.mandatory_skills || {},
          explanation: resumeData.insights[0]?.explanation || "",
          recommendation: resumeData.insights[0]?.recommendation || "",
          customer: resumeData.current_requirement?.customer,
        }));
      } else if (resumesResponse && resumesResponse.resume) {
        // Case where fetchResumesFromPool returns an object with a single resume
        const resumeData = resumesResponse.resume;
        profilesData = [
          {
            resume_id: resumeData.resume_id,
            jd_id: resumeData.requirement_id,
            candidate_fname: resumeData.insights[0]?.first_name || "",
            candidate_last_name: resumeData.insights[0]?.last_name || "",
            candidate_phone_number: resumeData.phone_number || "",
            emailAddress: resumeData.insights[0]?.email_id || "",
            state: resumeData.state || "",
            file_link: resumeData.file_link,
            remarks: resumeData.remarks || "",
            skills: resumeData.insights[0]?.mandatory_skills || {},
            explanation: resumeData.insights[0]?.explanation || "",
            recommendation: resumeData.insights[0]?.recommendation || "",
            customer: resumeData.current_requirement?.customer,
          },
        ];
      } else {
        console.error(
          "Invalid response format from fetchResumesFromPool:",
          resumesResponse
        );
        throw new Error("Invalid response format");
      }

      console.log("Fetched Profiles:", profilesData);
      // Set state values based on fetched profilesData
      if (profilesData.length > 0) {
        setProfiles(profilesData);
        fetchComments(profilesData[0].resume_id);
        setResumeID(profilesData[0].resume_id);
        setRequirementId(profilesData[0].jd_id);
        setCustomerId(profilesData[0].customer);
      } else {
        // Handle case where no profiles were fetched
        setProfiles([]);
        setResumeID(null);
        setRequirementId(null);
      }
    } catch (error) {
      console.error("Failed to fetch resumes:", error);
      setProfiles([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchResumesByStatus = async (status) => {
    const token = localStorage.getItem("token");

    const cacheBuster = new Date().getTime();

    const response = await fetch(
      `https://api.jinnhire.in/jinnhire/data/requirements/resumes_by_state_and_user?state=${status}&user_id=${parsedUserData.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  };

  const fetchResumesFromPool = async (statusBy = "default") => {
    const defaultURL = `https://api.jinnhire.in/jinnhire/data/resumes/assign_resume_from_pool/`;
    const poolURL = `https://api.jinnhire.in/jinnhire/data/resumes/assign_resume_from_pool_with_statemachine/`;
    let additionalParams = {};

    switch (statusBy) {
      case "communication_failed":
        additionalParams = {
          reason_for_conversation_stopping: "communication_failed",
        };
        break;
      case "suitable_profiles":
        additionalParams = { reason_for_conversation_stopping: "success" };
        break;
      case "profiles_needing_intervention":
        additionalParams = {
          reason_for_conversation_stopping: "not_interested",
        };
        break;
      case "unsuccessful_profiles":
        additionalParams = {
          reason_for_conversation_stopping: "not_qualified",
        };
        break;
      case "in_progress":
        additionalParams = { reason_for_conversation_stopping: "continuing" };
        break;
      case "no_response":
        additionalParams = {
          reason_for_conversation_stopping: "waiting",
          conversation_continuing: "YES",
        };
        break;
      case "dormant_profiles":
        additionalParams = {
          reason_for_conversation_stopping: "waiting",
          conversation_continuing: "FUTURE",
        };
        break;
      default:
        additionalParams = {};
        break;
    }

    try {
      const token = localStorage.getItem("token");
      const url = statusBy === "default" ? defaultURL : poolURL;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        params: additionalParams,
      });
      const resume = response?.data?.resume || {};
      setResumeID(resume.resume_id || null);
      setRequirementId(resume.requirement_id || null);

      // const phoneNumber = resume.phone_number;

      // if (phoneNumber) {
      //   try {
      //     const { data } = await axios.get(
      //       `https://api.jinnhire.in/jinnhire/data/candidate-state-machines/candidate-by-phone/?candidate_phone_number=${phoneNumber}`,
      //       {
      //         headers: {
      //           "Content-Type": "application/json",
      //           Authorization: `Token ${token}`,
      //         },
      //       }
      //     );
      //     setCandidateData(data);
      //   } catch (error) {
      //     console.error("Error fetching candidate data:", error);
      //   }
      // } else {
      //   console.log("Phone number is missing.");
      // }

      return response.data;
    } catch (error) {
      console.error("Error fetching resumes from pool:", error);
      throw error;
    }
  };

  const handleTabChange = (event, tabIndex) => {
    if (selectedTab !== tabIndex) {
      setSelectedTab(tabIndex);
      // setLoading(true);
    }
  };

  const handleSortChange = (event) => {
    setStatusBy(event.target.value);
    handleSkip(resumeId);
    fetchCommunicationFailedProfiles(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (isSuitable, profile) => {
    setModalContent(isSuitable ? suitableOptions : notSuitableOptions);
    setSelectedProfile(profile);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProfile(null);
    setSelectedOptions([]);
    setCommentInput("");
  };

  const openCandidateInteractionModal = (phoneNumber) => {
    setShowModal(true);
    fetchCandidateByPhone(phoneNumber);
    fetchQuestions();
  };

  const openCandidateModal = (phoneNumber) => {
    fetchCandidateByPhone(phoneNumber);
    setIsModalOpen(true);
  };

  const closeCandidateModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenCommentsModal = () => setCommentsModalOpen(true);
  const handleCloseCommentsModal = () => {
    setCommentsModalOpen(false);
    setCommentInput("");
    setSelectedComment("");
  };

  const handleCommentChange = (event) => {
    const value = event.target.value;
    setSelectedComment(value);
    if (value !== "Other (Please specify below)") {
      setCommentInput(value);
    } else {
      setCommentInput("");
    }
  };

  const handleInputChange = (event) => {
    setCommentInput(event.target.value);
  };
  const handleCommentSubmit = () => {
    handleSkip(commentInput, resumeId);
    fetchCommunicationFailedProfiles(statusBy);
    setCommentsModalOpen(false);
    setCommentInput(""); // Clear the input after submission
    setSelectedComment("");
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (optionText, isChecked) => {
    if (optionText === "Other (Please specify below)") {
      if (isChecked) {
        // Add "Other" option if checked
        setSelectedOptions([...selectedOptions, optionText]);
      } else {
        // Remove "Other" option if unchecked
        setSelectedOptions(
          selectedOptions.filter((option) => option !== optionText)
        );
        // Clear comment input if "Other" is unchecked
        setCommentInput("");
      }
    } else {
      // For other options
      if (isChecked) {
        setSelectedOptions([...selectedOptions, optionText]);
      } else {
        setSelectedOptions(
          selectedOptions.filter((option) => option !== optionText)
        );
      }
    }
  };

  const handleAccordionChange = (index) => {
    setExpandedAccordion(expandedAccordion === index ? null : index);
  };

  const handleJobDescriptionClick = (jdId) => {
    fetchJD(jdId);
    setJdModalOpen(true);
  };

  const handleOptInChange = (requirementId) => {
    setOptedIn((prevOptedIn) => ({
      ...prevOptedIn,
      [requirementId]: !prevOptedIn[requirementId],
    }));
  };

  const handleMenuOpen = (event, profile) => {
    setAnchorEl(event.currentTarget);
    setSelectedProfile(profile);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedProfile(null);
  };

  const [expanded, setExpanded] = useState(false);

  // Toggle accordion state
  const handleCandidateAccordion = () => {
    setExpanded(!expanded);
  };

  const [expands, setExpands] = useState(false);

  // Toggle accordion state
  const handleCustomerAccordion = () => {
    setExpands(!expands);
  };

  const fetchJD = async (jdId) => {
    try {
      const response = await axios.get(
        `https://api.jinnhire.in/media/${jdId}/JD_${jdId}_body.txt`
      );
      setJdContent(response.data);
    } catch (error) {
      console.error("Error fetching JD", error);
    }
  };

  const fetchComments = async (resumeId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/skip_comments/",
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          params: { resume_id: resumeId },
        }
      );

      // Get current date and calculate 7 days ago
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // Milliseconds in 7 days ago

      // Filter and sort comments within the last 7 days
      const filteredComments = response.data
        .filter((item) => {
          const commentDate = new Date(item.created_at);
          return commentDate >= sevenDaysAgo && commentDate <= now;
        })
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // Set the filtered and sorted comments array into state
      setComments(filteredComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoadingComments(false);
    }
  };

  const handleCloseJdModal = () => {
    setJdModalOpen(false);
  };

  const parseQuestionsList = (questionsList) => {
    if (typeof questionsList !== "string") {
      console.error("Invalid questionsList input:", questionsList);
      return [];
    }

    const parsedQuestions = [];
    const regex = /"([^"]*)"/g;
    const matches = [...questionsList.matchAll(regex)].map((match) => match[1]);

    for (let i = 0; i < matches.length; i += 2) {
      const question = matches[i];
      const answer = matches[i + 1] || "N/A";
      parsedQuestions.push({ question, answer });
    }
    return parsedQuestions;
  };

  const getDifferenceInMinutesFromIST = (timestamp) => {
    const utcDate = new Date(timestamp);
    const now = new Date();
    const diff = Math.abs(now - utcDate);
    return Math.floor(diff / 1000 / 60); // Convert milliseconds to minutes
  };

  const fetchResumes = async (jd_id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/requirements/${jd_id}/resumes/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching resumes:", error);
      return [];
    }
  };

  const handleSubmit = async () => {
    console.log("cliked");
    if (!selectedProfile || !selectedOptions.length) return;

    const filteredOptions = selectedOptions.filter(
      (option) => option !== "Other (Please specify below)"
    );

    let remarks = filteredOptions.join(", ");

    if (
      selectedOptions.includes("Other (Please specify below)") &&
      commentInput.trim() !== ""
    ) {
      remarks += (remarks ? ", " : "") + commentInput;
    }

    try {
      const token = localStorage.getItem("token");
      const state = selectedOptions.some((selectedOption) =>
        suitableOptions.some(
          (suitableOption) => suitableOption.text === selectedOption
        )
      )
        ? "processed"
        : "processing_failed";

      // Call the first API to get the resume by phone number
      const resumeResponse = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/resume-insights/get-resume-by-phone/`,
        {
          params: {
            phone_number: selectedProfile.candidate_phone_number,
          },
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const resumeData = resumeResponse.data;

      // Call the second API to update the resume state
      await axios.patch(
        `https://api.jinnhire.in/jinnhire/data/resumes/${resumeData.resume_id}/update-state/`,
        {
          state: state,
          remarks: remarks,
          validity: null,
          processing_user_id: parsedUserData.id,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // Call the third API to unlock the resume
      await axios.post(
        `https://api.jinnhire.in/jinnhire/data/resumes/${resumeData.resume_id}/unlock/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      fetchCommunicationFailedProfiles(statusBy);
      setCommentInput("");
      // Call the fourth API to update the candidate processing state
      await axios.put(
        `https://api.jinnhire.in/jinnhire/data/candidate-state-machines/${selectedProfile.candidate_phone_number}/`,
        {
          candidate_processing_state: state,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      setShow(true);
      setSeverity("success");
      setMessage("Successfully updated candidate state.");

      // Refresh profiles after submission
      // Close the modal after submission
      setProfiles([]);
      setSelectedOptions([]);
      setSelectedProfile(null);
    } catch (error) {
      console.error("Error submitting", error);
      setProfiles([]);
      setSelectedOptions([]);
      setShow(true);
      setSeverity("error");
      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Failed to update candidate state.");
      }
    }
    handleCloseModal();
  };

  const updateLockStatus = async (phoneNumber, jd_id) => {
    const token = localStorage.getItem("token");
    const uniqueJdIds = [...new Set(profiles.map((profile) => profile.jd_id))];
    const lockStatusMap = {};
    const fileLinksMap = {};

    try {
      for (const jd_id of uniqueJdIds) {
        const resumes = await fetchResumes(jd_id);
        resumes.forEach((resume) => {
          lockStatusMap[resume.phone_number] = !!resume.locked_by?.id;
          fileLinksMap[resume.phone_number] = resume.file_link; // Assuming file_link is the correct field
        });
      }
      setLockStatus(lockStatusMap);
      setFileLinks(fileLinksMap);
    } catch (error) {
      console.error("Error updating lock status:", error);
      setShow(true);
      setMessage("Error updating lock status");
      setSeverity("error");
    }
  };

  const handleLockUnlock = async (phoneNumber, isLocked) => {
    const token = localStorage.getItem("token");

    try {
      // Step 1: Fetch resume details by phone number
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/resume-insights/get-resume-by-phone/?phone_number=${phoneNumber}`,
        { headers: { Authorization: `Token ${token}` } }
      );

      const resumeId = response.data.resume_id;

      // Step 2: Lock or unlock the resume based on current lock status
      if (lockStatus[phoneNumber]) {
        await axios.post(
          `https://api.jinnhire.in/jinnhire/data/resumes/${resumeId}/unlock/`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        setShow(true);
        setMessage("Resume unlocked successfully.");
        setSeverity("success");
      } else {
        await axios.post(
          `https://api.jinnhire.in/jinnhire/data/resumes/${resumeId}/lock/`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        setShow(true);
        setMessage("Resume locked successfully.");
        setSeverity("success");
      }

      // Step 3: Update lock status in state
      setLockStatus((prevLockStatus) => ({
        ...prevLockStatus,
        [phoneNumber]: !lockStatus[phoneNumber],
      }));
    } catch (error) {
      console.error("Error locking/unlocking resume:", error);
      setShow(true);
      setMessage("Error locking/unlocking resume.");
      setSeverity("error");
    }
  };

  // Function to fetch candidate data from the API
  const fetchCandidateByPhone = async (phoneNumber) => {
    const token = localStorage.getItem("token");

    // Check if phoneNumber is provided
    if (!phoneNumber) {
      console.log("Phone number is missing.");
      return;
    }

    try {
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/candidate-state-machines/candidate-by-phone/?candidate_phone_number=${phoneNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      // Assuming the response contains candidate data in a structure you need
      setCandidateData(response.data); // Adjust this according to your state management
    } catch (error) {
      console.error("Error fetching candidate data:", error);
    }
  };

  // Function to fetch questions from the API
  const fetchQuestions = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://api.jinnhire.in/jinnhire/data/requirements/read-excel-questions/",
        {
          requirement_id: profiles[0].jd_id,
          customer_id: 58, // assuming static customer_id for now
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setQuestions(response.data.questions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  // Handle modal open (renamed)
  const handleOpenQuestionsModal = () => {
    fetchQuestions();
    setIsQuestionsModalOpen(true);
  };

  // Handle modal close (renamed)
  const handleCloseQuestionsModal = () => {
    setIsQuestionsModalOpen(false);
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#4e8daf", color: "#fff" }}>
        {tableHeaders[selectedTab].map((header, index) => (
          <TableCell
            key={index}
            sx={{
              color: "#fff",
              ...(header === "Current Question" && { width: "300px" }),
              ...(header === "Actions" && { width: "160px" }),
            }}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderLoadingRow = () => (
    <TableRow>
      <TableCell colSpan={tableHeaders[selectedTab].length} align="center">
        <Typography variant="body1" gutterBottom>
          <CircularProgress color="secondary" size={24} /> Loading profiles...
        </Typography>
      </TableCell>
    </TableRow>
  );

  const renderNoDataRow = () => (
    <TableRow>
      <TableCell colSpan={tableHeaders[selectedTab].length} align="center">
        <Typography variant="body1" gutterBottom>
          No data available
        </Typography>
      </TableCell>
    </TableRow>
  );

  const formatRecruiterName = (email) => {
    if (!email) return "Unknown Recruiter";
    const name = email.split("@")[0];
    return name.replace(".", " ");
  };

  const filteredRequirements = optedInRequirements.filter((requirement) =>
    requirement.requirement_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const customer = customerData.find((customer) => customer.id === customerId);
  return (
    <ThemeProvider theme={theme}>
      <Container
        className="home-wrapper"
        style={{ backgroundColor: "#ffff", boxShadow: "none" }}
      >
        <Snackbar
          open={show}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          autoHideDuration={6000}
          message="Success"
          onClose={() => setShow(false)}
        >
          <Alert
            onClose={() => setShow(false)}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>

        <Box sx={{ display: "flex", "min-height": "60vh" }}>
          {/* Collapsible and Scrollable Vertical Tabs */}
          <Box sx={{ width: "240px", mr: 2 }}>
            <Paper
              sx={{
                borderRadius: "8px",
                backgroundColor: "#fcfcfc",
                overflow: "hidden",
                maxHeight: "70vh",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  padding: "15px",
                  backgroundColor: "rgb(28, 66, 109)",
                  color: "white",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {!isSearching ? (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        flexGrow: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => setIsSearching(true)}
                    >
                      Requirements
                    </Typography>
                    <SearchIcon
                      onClick={() => setIsSearching(true)}
                      sx={{ cursor: "pointer", color: "white" }}
                    />
                  </>
                ) : (
                  <InputBase
                    placeholder="Search requirements..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onBlur={() => {
                      setIsSearching(false);
                      // setSearchTerm("");
                    }}
                    sx={{
                      flexGrow: 1,
                      color: "white",
                      ml: 1,
                      "& .MuiInputBase-input": {
                        color: "white",
                      },
                      "& .MuiInputBase-root": {
                        borderBottom: "1px solid white",
                      },
                    }}
                  />
                )}
              </Box>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "calc(65vh - 70px)",
                  padding: "0 16px",
                }}
              >
                {filteredRequirements.length > 0 ? (
                  filteredRequirements
                    .sort((a, b) =>
                      b.active === a.active ? 0 : b.active ? 1 : -1
                    ) // Sort to show active first
                    .map((requirement, index) => (
                      <div key={`${requirement.requirement_id}-${index}`}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            width: "170px",
                            padding: 1,
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Switch
                            checked={requirement.active || false}
                            onChange={(event) => {
                              handleToggle(requirement, event);
                              if (
                                requirementId === requirement.requirement_id
                              ) {
                                handleSkip(resumeId);
                                setRequirementId("");
                                fetchCommunicationFailedProfiles(statusBy);
                              } else if (!requirementId) {
                                fetchCommunicationFailedProfiles(statusBy);
                              }
                            }}
                            color={requirement.active ? "success" : "error"}
                            size="small"
                          />
                          <Typography variant="body2">
                            {requirement.requirement_id}
                          </Typography>
                        </Box>
                        {index < filteredRequirements.length - 1 && <Divider />}
                      </div>
                    ))
                ) : (
                  <Box
                    sx={{
                      padding: 2,
                      textAlign: "center",
                      backgroundColor: "#f9f9f9",
                      marginTop: 2,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      No results found.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>

          {/* Table with Horizontal Scroll */}
          {resumeAssignmentStrategy === "pool_based" ? (
            loading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Skeleton Loader */}
                <Box sx={{ minWidth: "60vw", mt: 2 }}>
                  <Skeleton
                    variant="rectangular"
                    minWidth="60vw"
                    height={150}
                    sx={{ borderRadius: 2, mb: 2 }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="rectangular"
                    minWidth="60vw"
                    height={200}
                    sx={{ borderRadius: 2, mt: 2 }}
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    m: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mr: 2,
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Communication Status
                  </Typography>
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 160 }}
                  >
                    <Select
                      value={statusBy}
                      onChange={handleSortChange}
                      sx={{
                        "& .MuiSelect-select": {
                          paddingRight: "32px",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#e0e0e0",
                          },
                          "&:hover fieldset": {
                            borderColor: "#0666a0",
                          },
                        },
                        "& .MuiSelect-icon": {
                          color: "#0666a0",
                        },
                      }}
                    >
                      <MenuItem value="default">Default</MenuItem>
                      <MenuItem value="communication_failed">
                        Communication Failed
                      </MenuItem>
                      <MenuItem value="suitable_profiles">
                        Suitable Profiles
                      </MenuItem>
                      <MenuItem value="profiles_needing_intervention">
                        Profiles Needing Intervention
                      </MenuItem>
                      <MenuItem value="unsuccessful_profiles">
                        Unsuccessful Profiles
                      </MenuItem>
                      <MenuItem value="in_progress">In-Progress</MenuItem>
                      <MenuItem value="no_response">No-Response</MenuItem>
                      <MenuItem value="dormant_profiles">
                        Dormant Profiles
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {profiles.length === 0 ? (
                  <NoDataCard />
                ) : (
                  profiles.map((profile, index) => (
                    <Box>
                      {/* Add your pool based specific UI here */}
                      {/* Main Content */}
                      <Box
                        sx={{
                          flexGrow: 6,
                          borderRadius: "16px",
                          backgroundColor: "#fcfcfc",
                          borderColor: "#e0e0e0",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          // minWidth: "65vw",
                        }}
                      >
                        {/* Action Headers */}
                        <Box
                          sx={{
                            p: 1,
                            m: 1,
                            border: "1px solid #ddd",
                            borderRadius: 2,
                            boxShadow: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            ></Box>
                            <Chip
                              label={
                                profile.state
                                  ? formatStatus(profile.state)
                                  : "N/A"
                              }
                              color={getChipColor(profile.state)}
                              size="small"
                            />
                          </Box>

                          <Box sx={{ mb: 1 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                {/* New grid item for job icon */}
                                <Box display="flex" alignItems="center">
                                  <img
                                    src="./briefcase__2_.png"
                                    height={50}
                                    width={50}
                                    alt="Briefcase"
                                  />
                                </Box>
                                <Typography
                                  variant="header1"
                                  sx={{
                                    mr: 1,
                                    color: "#0666a0",
                                    fontWeight: 600,
                                    cursor: "pointer",
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                  }}
                                  onClick={() =>
                                    handleJobDescriptionClick(profile.jd_id)
                                  }
                                >
                                  {profile.jd_id}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Box display="flex" alignItems="center" mb={1}>
                                  <PersonIcon
                                    sx={{ mr: 1, color: "#0666a0" }}
                                  />
                                  <Typography variant="body2">
                                    {`${profile.candidate_fname} ${profile.candidate_last_name}` ||
                                      "N/A"}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                  <PhoneIcon sx={{ mr: 1, color: "#0666a0" }} />
                                  <Typography variant="body2">
                                    {profile.candidate_phone_number}
                                  </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                  <EmailIcon sx={{ mr: 1, color: "#0666a0" }} />
                                  <Typography variant="body2">
                                    {profile.emailAddress}
                                  </Typography>
                                </Box>
                              </Grid>
                              {optedInRequirements
                                .filter(
                                  (requirement) =>
                                    requirement.active &&
                                    profile.jd_id === requirement.requirement_id
                                )
                                .map((filteredRequirement) => (
                                  <Grid item xs={12} sm={4}>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mb={1}
                                    >
                                      <CurrencyRupee
                                        sx={{ mr: 1, color: "#0666a0" }}
                                      />
                                      <Typography variant="body2">
                                        {filteredRequirement.salary_offered_by_customer
                                          ? filteredRequirement.salary_offered_by_customer
                                          : "N/A"}
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mb={1}
                                    >
                                      <WorkHistory
                                        sx={{ mr: 1, color: "#0666a0" }}
                                      />
                                      <Typography variant="body2">
                                        {filteredRequirement.experience}
                                      </Typography>
                                    </Box>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      mb={1}
                                    >
                                      <LocationCity
                                        sx={{ mr: 1, color: "#0666a0" }}
                                      />
                                      <Typography variant="body2">
                                        {filteredRequirement.location}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                ))}
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              p: 0.5,
                            }}
                          >
                            {/* Box for the Candidate Conversation button */}
                            <Box sx={{ flex: 1 }}>
                              {/* {statusBy !== "communication_failed" && (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    "&:hover": {
                                      borderColor: "info.light",
                                      color: "info.light",
                                    },
                                    fontSize: "0.75rem",
                                    padding: "3px 8px",
                                    color: "#1d6da6",
                                    borderColor: "#1d6da6",
                                  }}
                                  onClick={() => {
                                    openCandidateModal(
                                      profile.candidate_phone_number
                                    );
                                  }}
                                  startIcon={<RemoveRedEyeOutlined />}
                                >
                                  Candidate Conversation
                                </Button>
                              )} */}
                              {statusBy !== "communication_failed" && (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    "&:hover": {
                                      borderColor: "info.light",
                                      color: "info.light",
                                    },
                                    fontSize: "0.75rem",
                                    padding: "3px 8px",
                                    color: "#1d6da6",
                                    borderColor: "#1d6da6",
                                  }}
                                  onClick={() =>
                                    openCandidateInteractionModal(
                                      profile.candidate_phone_number
                                    )
                                  } // Open modal on button click
                                  startIcon={<RemoveRedEyeOutlined />}
                                >
                                  Candidate Conversation
                                </Button>
                              )}
                              {/* <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  "&:hover": {
                                    borderColor: "info.light",
                                    color: "info.light",
                                  },
                                  fontSize: "0.75rem",
                                  padding: "3px 8px",
                                  color: "#1d6da6",
                                  borderColor: "#1d6da6",
                                }}
                                onClick={handleOpenQuestionsModal}
                                startIcon={<PanoramaFishEye />}
                              >
                                Question List
                              </Button> */}
                            </Box>

                            {/* Box for the rest of the buttons */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: 0.5,
                              }}
                            >
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "success.light",
                                  },
                                  fontSize: "0.75rem",
                                  padding: "3px 8px",
                                }}
                                onClick={() => handleOpenModal(true, profile)}
                              >
                                Approve
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "error.light",
                                  },
                                  fontSize: "0.75rem",
                                  padding: "3px 8px",
                                }}
                                onClick={() => handleOpenModal(false, profile)}
                              >
                                Reject
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                size="small"
                                sx={{
                                  "&:hover": {
                                    borderColor: "secondary.light",
                                    color: "secondary.light",
                                  },
                                  fontSize: "0.75rem",
                                  padding: "3px 8px",
                                }}
                                onClick={handleOpenCommentsModal}
                              >
                                Skip
                              </Button>
                            </Box>
                            {/* Modal for Question List */}
                            <RequirementQuestionsModal
                              open={isQuestionsModalOpen}
                              handleClose={handleCloseQuestionsModal}
                              questions={questions}
                            />
                          </Box>
                        </Box>

                        {/* Comments Section */}
                        <Box sx={{ display: "flex", gap: 2, padding: 2 }}>
                          <Paper
                            sx={{
                              flex: 1,
                              padding: 1,
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                mb: 1,
                                fontWeight: "bold",
                                backgroundColor: "#0d619f",
                                color: "white",
                                padding: "5px",
                                borderRadius: "8px",
                                pl: 2,
                              }}
                            >
                              Rejected Remarks
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                marginTop: "5px",
                                color: "text.secondary",
                                fontSize: "1rem",
                              }}
                            >
                              {profile.remarks && profile.remarks.length > 0
                                ? profile.remarks
                                : "No remarks available"}
                            </Typography>
                          </Paper>
                          {optedInRequirements
                            .filter(
                              (requirement) =>
                                requirement.active &&
                                profile.jd_id === requirement.requirement_id
                            )
                            .map((filteredRequirement) => (
                              <Paper
                                sx={{
                                  flex: 1,
                                  padding: 1,
                                  border: "1px solid #ddd",
                                  borderRadius: 2,
                                  boxShadow: 2,
                                  overflowY: "auto",
                                  maxHeight: "200px",
                                  position: "relative",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    mb: 1,
                                    fontWeight: "bold",
                                    backgroundColor: "#0d619f",
                                    color: "white",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    pl: 2,
                                  }}
                                >
                                  Processing Remarks
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    marginTop: "5px",
                                    color: "text.secondary",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {/* Content for Remarks */}
                                  {filteredRequirement.remarks_for_processing
                                    ? filteredRequirement.remarks_for_processing
                                    : "No remarks available"}
                                </Typography>
                              </Paper>
                            ))}
                          <Paper
                            sx={{
                              flex: 1,
                              padding: 1,
                              border: "1px solid #ddd",
                              borderRadius: 2,
                              boxShadow: 2,
                              overflowY: "auto",
                              maxHeight: "200px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                mb: 1,
                                fontWeight: "bold",
                                backgroundColor: "#0d619f",
                                color: "white",
                                padding: "5px",
                                borderRadius: "8px",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                pl: 2,
                              }}
                            >
                              Skipped Comments
                            </Typography>
                            {loadingComments ? (
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "text.primary",
                                  fontSize: "0.75rem",
                                }}
                              >
                                <CircularProgress color="secondary" size={24} />{" "}
                                Loading ...
                              </Typography>
                            ) : comments.length === 0 ? (
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "text.secondary",
                                  fontSize: "0.75rem",
                                }}
                              >
                                No Comments
                              </Typography>
                            ) : (
                              comments.map((comment, cIndex) => (
                                <Box
                                  key={cIndex}
                                  display="flex"
                                  alignItems="flex-start"
                                  mb={0.5}
                                  p={0.5}
                                  borderRadius="10px"
                                  border={1}
                                  borderColor="divider"
                                  sx={{
                                    fontSize: "0.75rem",
                                    overflow: "hidden",
                                  }} // Ensure overflow is managed
                                >
                                  <Avatar
                                    sx={{
                                      bgcolor: "#3a6db7",
                                      mr: 1,
                                      width: 24,
                                      height: 24, // Smaller avatar size
                                    }}
                                  />
                                  <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      mb={0.5}
                                      sx={{ overflow: "hidden" }} // Ensure overflow for this container
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: "medium",
                                          fontSize: "0.75rem",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {formatRecruiterName(
                                          comment.recruiter_name
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: "0.6rem",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap", // Prevents text from wrapping and truncates with ellipsis if too long
                                        }}
                                      >
                                        {new Date(
                                          comment.created_at
                                        ).toLocaleString()}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        lineHeight: 1.2,
                                        fontSize: "0.75rem",
                                        whiteSpace: "pre-wrap", // Ensures text wraps properly
                                        overflowWrap: "break-word", // Breaks long words to avoid overflow
                                        overflow: "hidden", // Ensures container does not overflow
                                      }}
                                    >
                                      {comment.comment}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))
                            )}
                          </Paper>
                        </Box>
                        {/* Skills and JD Details */}

                        <Box sx={{ padding: 2 }}>
                          {/* Section: What are we looking in the candidate? */}
                          <Accordion
                            expanded={expanded}
                            onChange={handleCandidateAccordion}
                            sx={{ marginBottom: 2 }}
                          >
                            <AccordionSummary
                              expandIcon={
                                expanded ? <ExpandLess /> : <ExpandMore />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{
                                backgroundColor: "#0d619f",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                              >
                                What are we looking in the candidate?
                              </Typography>
                            </AccordionSummary>

                            {/* Accordion Details: Content inside the accordion */}
                            <AccordionDetails>
                              {/* Skills Section */}
                              {/* Skill Chips: Required skills vs Skills with Occurrences */}
                              {profiles.map(
                                (profile, index) =>
                                  profile.skills && (
                                    <div
                                      key={index}
                                      style={{ marginBottom: "16px" }}
                                    >
                                      <Grid container spacing={3}>
                                        {/* Left: Required Skills (All skills) */}
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            Required Skills
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 1,
                                            }}
                                          >
                                            {Object.keys(profile.skills).map(
                                              (skill) => (
                                                <Chip
                                                  key={skill}
                                                  label={skill}
                                                  sx={{
                                                    backgroundColor: "#f0f0f0", // Light gray for required skills
                                                    color: "#000",
                                                    border:
                                                      "1px solid rgb(28, 66, 109)", // Border color
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#d7efff", // Hover color
                                                      cursor: "pointer",
                                                    },
                                                  }}
                                                />
                                              )
                                            )}
                                          </Box>
                                        </Grid>

                                        {/* Right: Skills with Occurrence (Skills with occurrence > 0) */}
                                        <Grid item xs={6}>
                                          <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            Present Skills
                                          </Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              gap: 1,
                                            }}
                                          >
                                            {Object.entries(profile.skills)
                                              .filter(
                                                ([skill, occurrence]) =>
                                                  occurrence > 0
                                              ) // Only skills with occurrence > 0
                                              .map(([skill, occurrence]) => (
                                                <Chip
                                                  key={skill}
                                                  label={`${skill} (${occurrence})`}
                                                  sx={{
                                                    backgroundColor: "#fff", // White background
                                                    color: "#000",
                                                    border:
                                                      "1px solid rgb(28, 66, 109)", // Border color
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "#d7efff", // Hover color
                                                      cursor: "pointer",
                                                    },
                                                  }}
                                                />
                                              ))}
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )
                              )}

                              {/* Explanation of JD */}
                              <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    flex: 1,
                                    border: "1px solid #ddd",
                                    borderRadius: 2,
                                    boxShadow: 2,
                                  }}
                                >
                                  <Typography
                                    variant="h5"
                                    sx={{ mb: 2, fontWeight: "bold" }}
                                  >
                                    Explanation of JD
                                  </Typography>
                                  <Divider
                                    sx={{
                                      height: 3,
                                      backgroundColor: "#0d619f",
                                    }}
                                  />
                                  {optedInRequirements
                                    .filter(
                                      (requirement) =>
                                        requirement.active &&
                                        profile.jd_id ===
                                          requirement.requirement_id
                                    )
                                    .map((filteredRequirement) => (
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          marginTop: "10px",
                                          color: "text.secondary",
                                          mb: 3,
                                        }}
                                        key={filteredRequirement.requirement_id}
                                      >
                                        {/* Content for Explanation of JD */}
                                        {filteredRequirement.description}
                                      </Typography>
                                    ))}
                                </Paper>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                          {/* Section: Customer Info */}
                          <Accordion
                            expanded={expands}
                            onChange={handleCustomerAccordion}
                            sx={{
                              marginBottom: 2,
                              borderRadius: "8px",
                              boxShadow: 2,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                expands ? <ExpandLess /> : <ExpandMore />
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                              sx={{
                                backgroundColor: "#0d619f",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                              >
                                Customer Information
                              </Typography>
                            </AccordionSummary>

                            {/* Accordion Details: Content inside the accordion */}
                            <AccordionDetails>
                              <Box sx={{ mb: 3 }}>
                                {/* Customer Basic Info */}
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  Customer Name
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "text.secondary" }}
                                >
                                  {customer?.name ||
                                    "No customer name available."}
                                </Typography>

                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", mt: 3 }}
                                >
                                  About Customer
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: "text.secondary" }}
                                >
                                  {customer?.about ||
                                    "No information available."}
                                </Typography>
                              </Box>

                              <Divider
                                sx={{ my: 2, backgroundColor: "#0d619f" }}
                              />

                              {/* Customer Agreement PDF */}
                              <Box sx={{ mb: 3 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  Customer Agreement
                                </Typography>
                                {customer?.pdf_link ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    href={customer.pdf_link}
                                    target="_blank"
                                    sx={{ mt: 1 }}
                                  >
                                    View Agreement (PDF)
                                  </Button>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    No agreement available.
                                  </Typography>
                                )}
                              </Box>

                              <Divider
                                sx={{ my: 2, backgroundColor: "#0d619f" }}
                              />

                              {/* Customer PDF Section */}
                              <Box sx={{ mb: 3 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "bold", mb: 1 }}
                                >
                                  Customer PDF
                                </Typography>
                                {customer?.customer_pdf_link ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    href={customer.customer_pdf_link}
                                    target="_blank"
                                    sx={{ mt: 1 }}
                                  >
                                    View Customer PDF
                                  </Button>
                                ) : (
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    No customer PDF available.
                                  </Typography>
                                )}
                              </Box>

                              <Divider
                                sx={{ my: 2, backgroundColor: "#0d619f" }}
                              />

                              {/* Payment Details */}
                              <Grid container spacing={3}>
                                {/* Left: Payment Amount */}
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", mb: 1 }}
                                  >
                                    Payment Amount
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "text.primary" }}
                                  >
                                    ₹
                                    {customer?.payment_amount || "Not Provided"}
                                  </Typography>
                                </Grid>

                                {/* Right: Days to Payment */}
                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", mb: 1 }}
                                  >
                                    Days to Payment
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "text.primary" }}
                                  >
                                    {customer?.days_to_payment ||
                                      "Not Provided"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>

                          {/* Recommendation */}
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Paper
                              sx={{
                                p: 2,
                                flex: 1,
                                border: "1px solid #ddd",
                                borderRadius: 2,
                                boxShadow: 2,
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ mb: 2, fontWeight: "bold" }}
                              >
                                Explanation of Resume
                              </Typography>
                              <Divider
                                sx={{ height: 3, backgroundColor: "#0d619f" }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  mt: 1,
                                  color: "text.secondary",
                                }}
                              >
                                {profile.explanation ||
                                  "No Resume explanation available"}
                              </Typography>

                              <Typography
                                variant="h5"
                                sx={{ mt: 3, mb: 2, fontWeight: "bold" }}
                              >
                                Recommendation
                              </Typography>
                              <Divider
                                sx={{ height: 3, backgroundColor: "#0d619f" }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  mt: 1,
                                  color: "text.secondary",
                                }}
                              >
                                {/* Content for Recommendation */}
                                {profile.recommendation ||
                                  "No recommendation available"}
                              </Typography>
                            </Paper>
                            <ResumeComponent
                              fileLink={
                                profile.file_link ||
                                fileLinks[profile.candidate_phone_number]
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))
                )}
              </Box>
            )
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                borderRadius: "16px",
                backgroundColor: "#fcfcfc",
                borderColor: "#e0e0e0",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              <Paper sx={{ bgcolor: "#e3f2fd" }}>
                <AppBar position="static">
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    aria-label="Profile tabs"
                    sx={{ bgcolor: "#0696cb" }}
                  >
                    <Tab label="Communication Failed" />
                    <Tab label="Suitable Profiles" />
                    <Tab label="Profiles Needing Intervention" />
                    <Tab label="Unsuccessful Profiles" />
                    <Tab label="In-Progress" />
                    <Tab label="No-Response" />
                    <Tab label="Dormant Profiles" />
                  </Tabs>
                </AppBar>
              </Paper>
              <Table
                size="small"
                sx={{
                  borderColor: "#e0e0e0",
                  borderStyle: "solid",
                  borderWidth: "1px",
                }}
              >
                {renderTableHeader()}
                <TableBody>
                  {loading
                    ? renderLoadingRow()
                    : profiles.length === 0
                    ? renderNoDataRow()
                    : profiles
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((profile, index) => (
                          <Fragment key={profile.candidate_phone_number}>
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell
                                onClick={() =>
                                  handleJobDescriptionClick(profile.jd_id)
                                }
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                {profile.jd_id || "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  padding: "12px 16px",
                                  borderBottom: "1px solid #e0e0e0",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {`${profile.candidate_fname} ${profile.candidate_last_name}` ||
                                    "N/A"}
                                </div>
                              </TableCell>
                              <TableCell>
                                {profile.candidate_phone_number || "N/A"}
                              </TableCell>
                              {selectedTab === 0 && (
                                <TableCell>
                                  {profile.remarks && profile.remarks.length > 0
                                    ? profile.remarks
                                    : "N/A"}
                                </TableCell>
                              )}
                              {selectedTab === 0 && (
                                <TableCell>
                                  {profile.skills && (
                                    <Accordion
                                      sx={{ boxShadow: "none", margin: 0 }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        sx={{
                                          minHeight: "auto",
                                          "& .MuiAccordionSummary-content": {
                                            margin: 0,
                                          },
                                        }}
                                      >
                                        <Typography variant="body2">
                                          View Skills
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails sx={{ padding: 0 }}>
                                        <Table size="small">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Skill</TableCell>
                                              <TableCell>Occurrence</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {Object.entries(profile.skills).map(
                                              (
                                                [skill, occurrence],
                                                skillIndex
                                              ) => (
                                                <TableRow key={skillIndex}>
                                                  <TableCell>{skill}</TableCell>
                                                  <TableCell>
                                                    {occurrence}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                </TableCell>
                              )}
                              {selectedTab !== 0 && (
                                <TableCell>
                                  {profile.current_question}
                                </TableCell>
                              )}
                              {selectedTab === 1 && (
                                <TableCell>
                                  {profile.reason_for_conversation_stopping ||
                                    "N/A"}
                                </TableCell>
                              )}
                              {selectedTab === 2 && (
                                <TableCell>
                                  {profile.reason_for_failure || "N/A"}
                                </TableCell>
                              )}
                              {selectedTab === 3 && (
                                <TableCell>
                                  {profile.waiting_time || "N/A"}
                                </TableCell>
                              )}
                              {selectedTab === 4 && (
                                <>
                                  <TableCell>
                                    {profile.last_follow_up_time || "N/A"}
                                  </TableCell>
                                  <TableCell>
                                    {getDifferenceInMinutesFromIST(
                                      profile.candidate_response_time
                                    ) + " mins" || "N/A"}
                                  </TableCell>
                                </>
                              )}
                              {selectedTab === 5 && (
                                <TableCell>
                                  {profile.follow_up_count || "N/A"}
                                </TableCell>
                              )}
                              <TableCell>
                                <IconButton
                                  onClick={(event) =>
                                    handleMenuOpen(event, profile)
                                  }
                                >
                                  <MoreVert />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(
                                    anchorEl && selectedProfile === profile
                                  )}
                                  onClose={handleMenuClose}
                                >
                                  <Tooltip
                                    title={
                                      lockStatus[profile.candidate_phone_number]
                                        ? "Approve"
                                        : "Lock to approve"
                                    }
                                    placement="top"
                                  >
                                    <span>
                                      <MenuItem
                                        onClick={() => {
                                          // if (
                                          //   lockStatus[
                                          //     profile.candidate_phone_number
                                          //   ]
                                          // ) {
                                          handleOpenModal(true, profile);
                                          // }
                                          // handleMenuClose();
                                        }}
                                        disabled={
                                          !lockStatus[
                                            profile.candidate_phone_number
                                          ]
                                        }
                                      >
                                        <Check
                                          fontSize="small"
                                          color="success"
                                        />
                                        Approve
                                      </MenuItem>
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      lockStatus[profile.candidate_phone_number]
                                        ? "Reject"
                                        : "Lock to reject"
                                    }
                                    placement="top"
                                  >
                                    <span>
                                      <MenuItem
                                        onClick={() => {
                                          // if (
                                          //   lockStatus[
                                          //     profile.candidate_phone_number
                                          //   ]
                                          // ) {
                                          handleOpenModal(false, profile);
                                          // }
                                          // handleMenuClose();
                                        }}
                                        disabled={
                                          !lockStatus[
                                            profile.candidate_phone_number
                                          ]
                                        }
                                      >
                                        <Cancel
                                          fontSize="small"
                                          color="error"
                                        />
                                        Reject
                                      </MenuItem>
                                    </span>
                                  </Tooltip>
                                  <MenuItem
                                    onClick={() => {
                                      const fileLink =
                                        profile.file_link ||
                                        fileLinks[
                                          profile.candidate_phone_number
                                        ];
                                      if (fileLink) {
                                        window.open(fileLink, "_blank");
                                      } else {
                                        console.error(
                                          "File link is not available"
                                        );
                                      }
                                      handleMenuClose();
                                    }}
                                  >
                                    <Tooltip
                                      title="Download Resume"
                                      placement="top"
                                    >
                                      <Download fontSize="small" color="info" />
                                    </Tooltip>
                                    Download
                                  </MenuItem>
                                  {/* <MenuItem
                                    onClick={() => {
                                      handleLockUnlock(
                                        profile.candidate_phone_number,
                                        profile.phone,
                                        lockStatus[
                                          profile.candidate_phone_number
                                        ]
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        lockStatus[
                                          profile.candidate_phone_number
                                        ]
                                          ? "Unlock"
                                          : "Lock"
                                      }
                                      placement="top"
                                    >
                                      {lockStatus[
                                        profile.candidate_phone_number
                                      ] ? (
                                        <LockOpen fontSize="small" />
                                      ) : (
                                        <Lock fontSize="small" color="action" />
                                      )}
                                    </Tooltip>
                                    {lockStatus[profile.candidate_phone_number]
                                      ? "Unlock"
                                      : "Lock"}
                                  </MenuItem> */}
                                </Menu>
                              </TableCell>

                              {selectedTab !== 0 && (
                                <TableCell>
                                  <Accordion
                                    expanded={expandedAccordion === index + 1}
                                    onChange={() =>
                                      handleAccordionChange(index + 1)
                                    }
                                  >
                                    <AccordionSummary
                                      expandIcon={<RemoveRedEye />}
                                    >
                                      View
                                    </AccordionSummary>
                                  </Accordion>
                                </TableCell>
                              )}
                            </TableRow>
                            {selectedTab !== 0 && (
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={10}
                                >
                                  <Collapse in={expandedAccordion}>
                                    <Box sx={{ margin: 1 }}>
                                      <Typography variant="h6">
                                        Questions List
                                      </Typography>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>SNO</TableCell>
                                            <TableCell>Question</TableCell>
                                            <TableCell>Answer</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {parseQuestionsList(
                                            profile.questions_list
                                          ).map((question, qIndex) => (
                                            <TableRow key={qIndex}>
                                              <TableCell>
                                                {qIndex + 1}
                                              </TableCell>
                                              <TableCell>
                                                {question.question}
                                              </TableCell>
                                              <TableCell>
                                                {question.answer}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            )}
                            {/* Add the comments section */}
                            <TableRow>
                              <TableCell colSpan={6} style={{ padding: 0 }}>
                                <Collapse in={true}>
                                  <Box sx={{ margin: 2 }}>
                                    <Typography
                                      variant="h5"
                                      sx={{ mb: 2, fontWeight: "bold" }}
                                    >
                                      Comments
                                    </Typography>
                                    {loadingComments ? (
                                      <Typography
                                        variant="body1"
                                        sx={{ color: "text.primary" }}
                                      >
                                        Loading...
                                      </Typography>
                                    ) : comments.length === 0 ? (
                                      <Typography
                                        variant="body1"
                                        sx={{ color: "text.secondary" }}
                                      >
                                        No Comments
                                      </Typography>
                                    ) : (
                                      comments.map((comment, cIndex) => (
                                        <Box
                                          key={cIndex}
                                          display="flex"
                                          alignItems="flex-start"
                                          mb={1}
                                          p={1}
                                          borderRadius="20px"
                                          border={1}
                                          borderColor="divider"
                                        >
                                          <Avatar
                                            sx={{
                                              bgcolor: "#3a6db7",
                                              mr: 2,
                                              width: 40,
                                              height: 40,
                                            }}
                                          >
                                            {/* {formatRecruiterName(comment.recruiter_name)} */}
                                          </Avatar>
                                          <Box sx={{ flexGrow: 1 }}>
                                            <Box
                                              display="flex"
                                              justifyContent="space-between"
                                              mb={1}
                                            >
                                              <Typography
                                                variant="subtitle1"
                                                sx={{ fontWeight: "medium" }}
                                              >
                                                {formatRecruiterName(
                                                  comment.recruiter_name
                                                )}
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                color="textSecondary"
                                              >
                                                {new Date(
                                                  comment.created_at
                                                ).toLocaleString()}
                                              </Typography>
                                            </Box>
                                            <Typography
                                              variant="body2"
                                              sx={{ lineHeight: 1.6 }}
                                            >
                                              {comment.comment || "N/A"}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      ))
                                    )}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        ))}
                </TableBody>
              </Table>
              {/* Table Pagination */}
              {profiles.length >= 5 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={profiles.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}

              {/* Buttons Section */}
              {selectedTab === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end", // Aligns items to the right
                    alignItems: "center",
                    // marginTop: 2,
                    padding: 2,
                    borderTop: "1px solid #e0e0e0",
                  }}
                >
                  <button
                    onClick={handleOpenCommentsModal}
                    style={{
                      backgroundColor: "transparent",
                      color: "#1976d2",
                      border: "2px solid #1976d2",
                      borderRadius: "4px",
                      padding: "8px 16px",
                      fontSize: "16px",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                  >
                    Skip
                  </button>
                  <button
                    onClick={fetchCommunicationFailedProfiles}
                    style={{
                      backgroundColor:
                        profiles.length === 0 ? "#dc004e" : "transparent",
                      color: profiles.length === 0 ? "#fff" : "GrayText",
                      border: profiles.length === 0 ? "none" : "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "8px 16px",
                      fontSize: "16px",
                      cursor: profiles.length === 0 ? "pointer" : "not-allowed",
                    }}
                    disabled={profiles.length !== 0}
                  >
                    Next
                  </button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Container>
      <CandidateEvaluationModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        handleCheckboxChange={handleCheckboxChange}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        selectedOptions={selectedOptions}
        commentInput={commentInput}
        modalContent={modalContent}
      />
      <CandidateCommentsModal
        commentsModalOpen={commentsModalOpen}
        handleCloseCommentsModal={handleCloseCommentsModal}
        handleCommentChange={handleCommentChange}
        handleInputChange={handleInputChange}
        handleCommentSubmit={handleCommentSubmit}
        selectedComment={selectedComment}
        commentInput={commentInput}
        predefinedComments={predefinedComments}
      />
      <CandidateModal
        open={isModalOpen}
        onClose={closeCandidateModal}
        candidateData={candidateData}
      />
      <CandidateInteractionModal
        open={showModal} // Use showModal here
        onClose={() => setShowModal(false)}
        selectedTab={selectedTab}
        candidateData={profiles}
        questions={questions}
      />
      <Dialog open={jdModalOpen} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle>Job Description</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ whiteSpace: "pre-wrap" }}>
            <Typography variant="body1" gutterBottom>
              {jdContent}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseJdModal}
            color="error"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Process;
